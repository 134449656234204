import Vue from "vue";
import { BootstrapVue, BIcon, BIconSearch } from "bootstrap-vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAlignLeft,
  faAngleDoubleUp,
  faCheck,
  faCodeBranch,
  faExclamation,
  faHashtag,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import {
  faClock,
  faEdit,
  faEye,
  faSave
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueQuillEditor from "vue-quill-editor";
import InfiniteLoading from "vue-infinite-loading";

import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import GAuth from "@/services/googleOauth";

import IconBase from "@/components/common/icons/IconBase";
import IconApple from "@/components/common/icons/Apple";
import IconAndroid from "@/components/common/icons/Android";
import IconMessage from "@/components/common/icons/Message";
import IconWorkflow from "@/components/common/icons/Workflow";
import IconCommit from "@/components/common/icons/Commit";
import ScrollTopArrow from "@/components/common/scroll/ScrollTopArrow";

import "@/services/filters";

const gAuthOptions = {
  clientId: process.env.VUE_APP_OAUTH_CLIENT_ID,
  scope: "profile email",
  prompt: "select_account"
};

// global register
Vue.use(VueQuillEditor);
Vue.use(GAuth, gAuthOptions);
Vue.use(BootstrapVue);
library.add([
  faEye,
  faCodeBranch,
  faClock,
  faSave,
  faEdit,
  faHashtag,
  faCheck,
  faTimes,
  faExclamation,
  faAlignLeft,
  faAngleDoubleUp
]);
Vue.use(InfiniteLoading);

// fontawesome icon
Vue.component("font-awesome-icon", FontAwesomeIcon);

// custom icon
Vue.component("icon-base", IconBase);
Vue.component("icon-apple", IconApple);
Vue.component("icon-android", IconAndroid);
Vue.component("IconMessage", IconMessage);
Vue.component("IconWorkflow", IconWorkflow);
Vue.component("IconCommit", IconCommit);

// Bootstrap icon
Vue.component("BIcon", BIcon);
Vue.component("BIconSearch", BIconSearch);

Vue.component("ScrollTopArrow", ScrollTopArrow);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
