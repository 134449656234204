<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      isInit: false,
      isSignIn: false
    };
  },
  created() {
    let checkGauthLoad = setInterval(() => {
      this.isInit = this.$gAuth.isInit;
      this.isSignIn = this.$gAuth.isAuthorized;

      this.isNotSignIn();

      if (this.isInit) {
        clearInterval(checkGauthLoad);
      }
    }, 1000);
  },
  watch: {
    isSignIn: "supervisor"
  },
  methods: {
    async supervisor(isSignIn) {
      if (isSignIn) {
        const googleUser = this.$gAuth.GoogleAuth.currentUser.get();
        this.$store.dispatch("auth/signIn", {
          profile: googleUser.getBasicProfile(),
          token: googleUser.getAuthResponse().id_token,
          authorized: this.$gAuth.isAuthorized
        });
      }
    },
    async isNotSignIn() {
      if (
        !this.isSignIn &&
        !!this.$route.meta &&
        !!this.$route.meta.authRequired
      ) {
        await this.$gAuth.signOut();
        this.$store.dispatch("auth/logout");
        this.$router.push({ name: "auth.login" });
      }
    }
  }
};
</script>
<style lang="scss">
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
