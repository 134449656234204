import Vue from "vue";
import VueRouter from "vue-router";

import store from "@/store";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  const authorized = store.getters["auth/isAuthorized"];

  if (to.matched.some(record => record.meta.authRequired) && !authorized) {
    next({
      name: "auth.login",
      query: { to: to.path }
    });

    return;
  }

  if (to.name === "auth.login" && authorized) {
    next({
      name: from.name || "admin.index"
    });

    return;
  }

  next();
});

export default router;
