<template>
  <icon-base
    :fillColor="fillColor"
    viewBox="0 0 24 24"
    :width="size"
    :height="size"
  >
    <path
      d="m 12.048339,14.663313 c 0.523914,0 0.945693,0.421779 0.945693,0.945693 v 5.392462 c 0,0.523914 -0.421779,0.945693 -0.945693,0.945693 -0.523914,0 -0.945694,-0.421779 -0.945694,-0.945693 v -5.392462 c 0,-0.523914 0.42178,-0.945693 0.945694,-0.945693 z m 0,-13.325302 c 0.523914,0 0.945693,0.4217792 0.945693,0.9456932 v 5.3924629 c 0,0.523914 -0.421779,0.945693 -0.945693,0.945693 -0.523914,0 -0.945694,-0.421779 -0.945694,-0.945693 V 2.2837042 c 0,-0.523914 0.42178,-0.9456932 0.945694,-0.9456932 z m 4,10.328986 a 4,4 0 0 1 -4,4 4,4 0 0 1 -4.0000001,-4 4,4 0 0 1 4.0000001,-4.0000002 4,4 0 0 1 4,4.0000002 z"
    />
  </icon-base>
</template>

<script>
export default {
  name: "AppleIcon",
  props: {
    title: {
      type: String,
      default: "Apple icon"
    },
    fillColor: {
      type: String,
      default: "currentColor"
    },
    size: {
      type: Number,
      default: 24
    }
  }
};
</script>
