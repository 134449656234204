const routes = [
  {
    path: "/",
    redirect: "auth/login"
  },
  {
    path: "/auth",
    component: () => import("@/layouts/auth/Layout.vue"),
    children: [
      {
        path: "",
        redirect: "login"
      },
      {
        path: "login",
        component: () => import("@/pages/auth/Login.vue"),
        name: "auth.login"
      }
    ]
  },
  {
    path: "/admin",
    component: () => import("@/layouts/admin/Layout.vue"),
    meta: {
      authRequired: true
    },
    children: [
      {
        path: "",
        name: "admin.index",
        component: () => import("@/pages/admin/Index.vue"),
        meta: {
          breadcrumb: "Apps",
          authRequired: true
        }
      },
      {
        path: ":app_slug/builds",
        name: "admin.builds",
        component: () => import("@/pages/admin/Builds.vue"),
        props: true,
        meta: {
          breadcrumb: "Builds",
          authRequired: true
        },
        children: [
          {
            path: ":build_slug/artifacts",
            name: "admin.artifacts",
            component: () => import("@/pages/admin/Artifacts.vue"),
            props: true,
            meta: {
              breadcrumb: "Artifacts",
              authRequired: true
            }
          }
        ]
      }
    ]
  },
  {
    path: "/:path",
    component: () => import("@/layouts/user/Layout.vue"),
    children: [
      {
        path: "",
        name: "client.index",
        component: () => import("@/pages/user/Index.vue"),
        props: true,
        children: [
          {
            path: ":build_slug/artifacts",
            name: "client.artifacts",
            component: () => import("@/pages/user/Artifacts.vue"),
            props: true
          }
        ]
      }
    ]
  },
  {
    path: "/pages",
    component: () => import("@/layouts/user/Layout.vue"),
    children: [
      {
        path: "error-404",
        name: "page-error-404",
        component: () => import("@/pages/Error404.vue")
      }
    ]
  },
  // Redirect to 404 page, if no match found
  {
    path: "*",
    redirect: "/pages/error-404"
  }
];

export default routes;
