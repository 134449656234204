/* eslint-disable no-unused-vars */
import axios from "@/services/axios";

export const apps = async ({ commit }, options = null) => {
  const { data: response } = await axios.get("admin/apps", { params: options });
  return response;
};

export const app = async ({ commit }, { app_slug, options = null }) => {
  const { data: response } = await axios.get(`admin/apps/${app_slug}`);
  return response.data;
};

export const updateApp = async ({ commit }, { app_slug, data }) => {
  const { data: response } = await axios.post(`admin/apps/${app_slug}`, data);
  return response;
};

export const builds = async ({ commit }, { app_slug, options = null }) => {
  const { data: response } = await axios.get(`admin/apps/${app_slug}/builds`, {
    params: options
  });
  return response;
};

export const updateBuild = async ({ commit }, { build_slug, data }) => {
  const { data: response } = await axios.post(
    `admin/builds/${build_slug}`,
    data
  );
  return response;
};

export const build = async (
  { commit },
  { app_slug, build_slug, options = null }
) => {
  const { data: response } = await axios.get(
    `admin/apps/${app_slug}/builds/${build_slug}`
  );
  return response.data;
};

export const artifacts = async (
  { commit },
  { app_slug, build_slug, options = null }
) => {
  const { data: response } = await axios.get(
    `admin/apps/${app_slug}/builds/${build_slug}/artifacts`
  );
  return response;
};

export const updateArtifact = async ({ commit }, { artifact_slug, data }) => {
  const { data: response } = await axios.post(
    `admin/artifacts/${artifact_slug}`,
    data
  );
  return response;
};

export const getBuildsForClient = async ({ commit }, path) => {
  const { data: response } = await axios.get(`client/${path}`);
  return response.data;
};

export const getArtifactsForClient = async ({ commit }, { build_slug }) => {
  const { data: response } = await axios.get(`client/${build_slug}/artifacts`);
  return response.data;
};
