export const signIn = async ({ commit }, data) => {
  commit("LOGIN", data);
};

export const logout = async ({ commit }) => {
  try {
    localStorage.removeItem("user");
    commit("LOGOUT");
  } catch (err) {
    console.error(err);
    throw err;
  }
};
