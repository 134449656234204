import Vue from "vue";

Vue.filter("capitalize", function(value) {
  if (!value) return "";
  value = value.toString();
  const arr = value.split(" ");
  const capitalized_array = [];
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalized_array.push(capitalized);
  });
  return capitalized_array.join(" ");
});

Vue.filter("title", function(value, replacer = "_") {
  if (!value) return "";
  value = value.toString();

  const arr = value.split(replacer);
  const capitalized_array = [];
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalized_array.push(capitalized);
  });
  return capitalized_array.join(" ");
});

Vue.filter("truncate", function(value, limit) {
  if (value) {
    return value.substring(0, limit);
  }

  return value;
});

Vue.filter("upperCase", function(value) {
  if (value) {
    return value.toUpperCase();
  }

  return value;
});
