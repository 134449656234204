<template>
  <scroll-top>
    <a class="btn btn-light">
      <font-awesome-icon :icon="['fas', 'angle-double-up']" size="2x" />
    </a>
  </scroll-top>
</template>

<script>
import ScrollTop from "./ScrollTop";
export default {
  components: {
    ScrollTop
  }
};
</script>

<style lang="scss" scoped>
.btn {
  border-radius: 0.2rem !important;
  background-color: var(--secondary);
  color: #ffffff;
  border: 0;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.15);
  padding-top: 0.5rem;
  padding-left: 0.65rem;
  padding-right: 0.65rem;
  padding-bottom: 0.5rem;

  &:hover,
  &:active {
    color: #ffffff;
    background-color: darken(#0d0042, 5%);
    border-color: currentColor;
  }

  &.btn-light:active {
    color: #ffffff !important;
    background-color: darken(#0d0042, 5%) !important;
    border-color: currentColor !important;
  }
}
</style>
