/* eslint-disable no-prototype-builtins */
import axios from "axios";
import store from "@/store";

const errorResponseHandler = error => {
  // // check for errorHandle config
  // if (
  //   error.config.hasOwnProperty("errorHandle") &&
  //   error.config.errorHandle === false
  // ) {
  return Promise.reject(error);
  // }
};

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_URL_API,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

axiosInstance.interceptors.request.use(function(config) {
  const authKey = store.getters["auth/token"];
  config.headers["Authorization"] = `Bearer ${authKey}`;
  return config;
});

axiosInstance.interceptors.response.use(
  response => response,
  errorResponseHandler
);

export default axiosInstance;
