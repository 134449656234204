<template>
  <icon-base
    :fillColor="fillColor"
    viewBox="0 0 24 24"
    :width="size"
    :height="size"
  >
    <g>
      <path
        d="M 17.34375,4.125 H 22.5 c 0.259687,0 0.46875,0.2090625 0.46875,0.46875 v 1.4062498 c 0,0.2596875 -0.209063,0.46875 -0.46875,0.46875 h -5.15625 c -0.259687,0 -0.46875,-0.2090625 -0.46875,-0.46875 V 4.59375 c 0,-0.2596875 0.209063,-0.46875 0.46875,-0.46875 z"
      />
      <path
        d="M 1.4062501,4.125 H 15.46875 c 0.259688,0 0.46875,0.2090625 0.46875,0.46875 v 1.4335542 c 0,0.2596875 -0.209062,0.46875 -0.46875,0.46875 H 1.4062501 c -0.2596875,0 -0.46875004,-0.2090625 -0.46875004,-0.46875 V 4.59375 c 0,-0.2596875 0.20906254,-0.46875 0.46875004,-0.46875 z"
      />
      <path
        d="M 1.4062501,10.753946 H 12.1875 c 0.259687,0 0.46875,0.209063 0.46875,0.46875 v 1.433554 c 0,0.259688 -0.209063,0.46875 -0.46875,0.46875 H 1.4062501 C 1.1465626,13.125 0.9375,12.915938 0.9375,12.65625 v -1.433554 c 0,-0.259687 0.2090626,-0.46875 0.4687501,-0.46875 z"
      />
      <path
        d="m 14.0625,10.78125 h 1.40625 c 0.259687,0 0.46875,0.209063 0.46875,0.46875 v 1.433554 c 0,0.259688 -0.209063,0.46875 -0.46875,0.46875 H 14.0625 c -0.259687,0 -0.46875,-0.209062 -0.46875,-0.46875 V 11.25 c 0,-0.259687 0.209063,-0.46875 0.46875,-0.46875 z"
      />
      <path
        d="M 17.34375,10.753946 H 18.75 c 0.259687,0 0.46875,0.209063 0.46875,0.46875 v 1.433554 c 0,0.259688 -0.209063,0.46875 -0.46875,0.46875 h -1.40625 c -0.259687,0 -0.46875,-0.209062 -0.46875,-0.46875 v -1.433554 c 0,-0.259687 0.209063,-0.46875 0.46875,-0.46875 z"
      />
      <path
        d="m 1.4062501,17.316446 h 1.4062498 c 0.2596875,0 0.4687501,0.209063 0.4687501,0.46875 v 1.433554 c 0,0.259688 -0.2090626,0.46875 -0.4687501,0.46875 H 1.4062501 C 1.1465626,19.6875 0.9375,19.478438 0.9375,19.21875 v -1.433554 c 0,-0.259687 0.2090626,-0.46875 0.4687501,-0.46875 z"
      />
      <path
        d="m 4.6875001,17.289143 h 1.4062498 c 0.2596875,0 0.4687501,0.209062 0.4687501,0.46875 v 1.433554 c 0,0.259687 -0.2090626,0.46875 -0.4687501,0.46875 H 4.6875001 c -0.2596875,0 -0.4687501,-0.209063 -0.4687501,-0.46875 v -1.433554 c 0,-0.259688 0.2090626,-0.46875 0.4687501,-0.46875 z"
      />
      <path
        d="M 7.9687501,17.316446 H 15.46875 c 0.259687,0 0.46875,0.209063 0.46875,0.46875 v 1.433554 c 0,0.259688 -0.209063,0.46875 -0.46875,0.46875 H 7.9687501 C 7.7090625,19.6875 7.5,19.478438 7.5,19.21875 v -1.433554 c 0,-0.259687 0.2090625,-0.46875 0.4687501,-0.46875 z"
      />
    </g>
  </icon-base>
</template>

<script>
export default {
  name: "AppleIcon",
  props: {
    title: {
      type: String,
      default: "Apple icon"
    },
    fillColor: {
      type: String,
      default: "currentColor"
    },
    size: {
      type: Number,
      default: 24
    }
  }
};
</script>
