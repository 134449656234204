const { token, ...user } = JSON.parse(localStorage.getItem("user")) || {
  authorized: false,
  profile: null,
  token: null
};

export default {
  user,
  token
};
