export const LOGIN = (state, { profile, token, authorized }) => {
  profile = {
    fullName: profile.getName(),
    name: profile.getGivenName(),
    lastName: profile.getFamilyName(),
    email: profile.getEmail(),
    image: profile.getImageUrl()
  };

  localStorage.setItem("user", JSON.stringify({ profile, token, authorized }));

  state.user = { profile, authorized };
  state.token = token;
};

export const LOGOUT = state => {
  state.user = {
    authorized: false,
    profile: {}
  };

  state.token = null;
  localStorage.clear();
};
